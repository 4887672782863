import { useCollection } from "../../hooks/useCollection"
import Navigation from "../../components/Navigation"
import GameCard from "../../components/admin/GameCard"
import { useEffect } from "react"
export default function GameList() {
  const pages = [
    { name: "Admin", path: "/admin", current: false },
    { name: "Game List", path: "", current: true },
  ]

  const { documents: games } = useCollection("games")

  useEffect(() => {
    console.log("RUNNING AGAIN")
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "virtualPageview",
      pageUrl: window.location.href,
      pageTitle: "Game List",
    })
  }, [])

  const isActive = (game) => {
    return (
      game.startDate &&
      game.startDate.toDate() < new Date() &&
      game.endDate.toDate() > new Date()
    )
  }

  const isUpcoming = (game) => {
    return (
      !game.startDate ||
      (game.startDate && game.startDate.toDate() > new Date())
    )
  }

  const isPast = (game) => {
    return game.endDate && game.endDate.toDate() < new Date()
  }

  return (
    <div>
      <Navigation pages={pages} />

      <div className="my-6 mx-6">
        <h1 className="text-3xl font-bold mb-4">Active Game List</h1>
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {games &&
            games.map((game) => {
              if (isActive(game)) {
                return <GameCard game={game} key={game.id} />
              } else {
                return null
              }
            })}
        </ul>
      </div>

      <div className="my-6 mx-6">
        <h1 className="text-3xl font-bold mb-4">Upcoming Game List</h1>
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {games &&
            games.map((game) => {
              if (isUpcoming(game)) {
                return <GameCard game={game} key={game.id} />
              } else {
                return null
              }
            })}
        </ul>
      </div>

      <div className="my-6 mx-6">
        <h1 className="text-3xl font-bold mb-4">Past Game List</h1>
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {games &&
            games.map((game) => {
              if (isPast(game)) {
                return <GameCard game={game} key={game.id} />
              } else {
                return null
              }
            })}
        </ul>
      </div>
    </div>
  )
}
