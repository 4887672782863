import { initializeApp } from "firebase/app"
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check"
import { Timestamp, connectFirestoreEmulator, getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth"
import { getAnalytics } from "firebase/analytics"

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY, //"AIzaSyCdgcqjqrHL9YAPO_QRBOwVIJTZUJLHYyQ",
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app);

if (process.env.REACT_APP_ENV === "development") {
    window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider("6LfvqeUlAAAAAO9sxlYZF9kf2waKDUT_zv3Cg6CS"),
    isTokenAutoRefreshEnabled: true
})

const auth = getAuth()

const db = getFirestore()
if (window.location.hostname === "localhost") {
    connectFirestoreEmulator(db, 'localhost', 8080);
}


const timestamp = Timestamp

export { auth, db, timestamp }