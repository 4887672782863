import { createContext, useReducer, useEffect } from "react"
import { auth, db } from "../firebase/config"
import { onAuthStateChanged } from "firebase/auth"
import { doc, getDoc } from "firebase/firestore"

export const AuthContext = createContext()

export const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: action.payload.user,
        isAdmin: action.payload.isAdmin,
        userData: action.payload.userData,
      }
    case "LOGOUT":
      return { ...state, user: null, isAdmin: false, userData: null }
    case "AUTH_IS_READY":
      return {
        user: action.payload.user,
        authIsReady: true,
        isAdmin: action.payload.isAdmin,
        userData: action.payload.userData,
      }
    default:
      return state
  }
}

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    authIsReady: false,
    isAdmin: false,
  })

  async function getUserData(uid) {
    const ref = doc(db, "users", uid)
    const docSnap = await getDoc(ref)
    const data = docSnap.data()
    if (data) {
      return data
    } else {
      return {}
    }
  }

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, async (user) => {
      let isAdmin = false
      let userData = {}
      if (user) {
        const res = await auth.currentUser.getIdTokenResult()
        isAdmin = res.claims.admin
        userData = await getUserData(user.uid)
        console.log(userData)
      }

      dispatch({
        type: "AUTH_IS_READY",
        payload: { user: user, isAdmin: isAdmin, userData: userData },
      })
      unsub()
    })
  }, [])

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  )
}
