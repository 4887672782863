import { useEffect, useState } from "react"
import { db } from "../firebase/config"
import { onSnapshot, doc } from "@firebase/firestore"

export const useDocument = (c, id) => {
  const [document, setDocument] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    const ref = doc(db, c, id)

    const unsub = onSnapshot(
      ref,
      (snapshot) => {
        if (snapshot.data()) {
          setDocument({ ...snapshot.data(), id: snapshot.id })
          setError(null)
        } else {
          setError("No such document exists")
        }
      },
      (error) => {
        console.log(error.message)
        setError("Failed to get document")
      }
    )

    return () => unsub()
  }, [c, id])

  return { document, error }
}

// export const useCollection = (c, queries) => {
//   useEffect(() => {
//     const query = query(collection(db, c), ...queries)

//     const unsub = onSnapshot(query, (snapshot) => {
//       snapshot.forEach((doc) => {
//         console.log(doc.id, " => ", doc.data())
//       })
//     })

//     return () => unsub()
//   }, [c, queries])
// }
