import { FacebookAuthProvider, GoogleAuthProvider } from "@firebase/auth"
import { useOAuthLogin } from "../hooks/useOAuthLogin"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons"

export default function OAuth() {
  const { error, oAuthLogin } = useOAuthLogin()

  const handleGoogleSubmit = (e) => {
    e.preventDefault()
    const provider = new GoogleAuthProvider()
    oAuthLogin(provider)
  }

  const handleFacebookSubmit = (e) => {
    e.preventDefault()
    const provider = new FacebookAuthProvider()
    oAuthLogin(provider)
  }

  return (
    <div>
      <div className="flex justify-center w-full mb-6">
        {/* <FcGoogle
          onClick={handleGoogleSubmit}
          className="cursor-pointer text-2xl bg-white rounded-full mx-2"
        /> */}
        <FontAwesomeIcon
          icon={faGoogle}
          onClick={handleGoogleSubmit}
          className="cursor-pointer text-blue-500 text-2xl bg-white rounded-full mx-2"
        />
        {/* <FontAwesomeIcon
          icon={faFacebook}
          onClick={handleFacebookSubmit}
          className="cursor-pointer text-blue-600 text-2xl bg-white rounded-full mx-2"
        /> */}
        {/* <BsFacebook
          onClick={handleFacebookSubmit}
          className="cursor-pointer text-blue-600 text-2xl bg-white rounded-full mx-2"
        /> */}
      </div>
      {error && <p>{error}</p>}
    </div>
  )
}
