import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useSignup } from "../hooks/useSignup"

export default function SignUp() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const { error, signup } = useSignup()

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "virtualPageview",
      pageUrl: window.location.href,
      pageTitle: "Sign Up",
    })
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    signup(email, password)
  }

  return (
    <section>
      <h1 className="text-3xl text-center mt-6 font-bold">Sign Up</h1>
      <div className="items-center justify-center flex">
        <div className="sm:w-full md:w-auto">
          <form onSubmit={handleSubmit}>
            <label>
              <span>Email:</span>
              <input
                required
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                className="mb-6 w-full px-4 py-2 text-xl text-gray-700 bg-white border-gray-300 rounded transition ease-in-out"
              />
            </label>
            <label>
              <span>Password:</span>
              <input
                required
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                className="w-full px-4 py-2 text-xl text-gray-700 bg-white border-gray-300 rounded transition ease-in-out"
              />
            </label>
            <div className="flex justify-between whitespace-nowrap">
              <p className="mb-6">
                Already have an account?
                <Link to="/sign-in" className="text-blue-600 ml-1">
                  Sign In
                </Link>
              </p>
              <p>
                <Link to="/forgot-password" className="text-blue-600 ml-1">
                  Forgot Password?
                </Link>
              </p>
            </div>
            <button
              className="w-full bg-blue-600 text-white px-7 py-3 mt-3 text-sm font-medium uppercase rounded shadow-md hover:bg-blue-700 transition duration-150 ease-in-out hover:shadow-lg active:bg-blue-800"
              type="submit"
            >
              sign up
            </button>

            {error && <p>{error}</p>}
          </form>
        </div>
      </div>
    </section>
  )
}
