import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { AuthContextProvider } from './context/AuthContext'
import ReactGA from 'react-ga4'

// ReactGA.initialize(process.env.REACT_APP_FIREBASE_MEASUREMENT_ID, {
//   gaOptions: {
//     debug_mode: process.env.REACT_APP_ANALYTICS_DEBUG_MODE,
//   },
//   gtagOptions: {
//     debug_mode: process.env.REACT_APP_ANALYTICS_DEBUG_MODE,
//   },
// })

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </React.StrictMode>
);

