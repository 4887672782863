import queryString from "query-string"

export default function Gravatar({ user, className, size = 50, md5 = true }) {
  const base = `https://www.gravatar.com/avatar/`

  const formattedEmail =
    user && user.email ? ("" + user.email).trim().toLowerCase() : ""

  const query = queryString.stringify({
    s: size,
    r: "g",
    d: "retro",
  })

  let hash
  if (md5) {
    hash = md5
  } else if (typeof user.email === "string") {
    hash = md5(formattedEmail, { encoding: "binary" })
  } else {
    console.warn(
      'Gravatar image can not be fetched. Either the "email" or "md5" prop must be specified.'
    )
    return <script />
  }

  const src = `${base}${hash}?${query}`
  return (
    <img
      alt="Gravatar"
      //   style={this.props.style}
      src={src}
      //   srcSet={`${retinaSrc} 2x`}
      height={size}
      width={size}
      className={className}
    />
  )
}
