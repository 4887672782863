import { auth, db } from "../firebase/config"
import { signInWithEmailAndPassword } from "firebase/auth"
import { useAuthContext } from "./useAuthContext"
import { toast } from "react-toastify"
import { doc, getDoc } from "firebase/firestore"

export const useLogin = () => {
  const { dispatch } = useAuthContext()

  async function getUserData(uid) {
    console.log("START")
    const ref = doc(db, "users", uid)
    const docSnap = await getDoc(ref)
    const data = docSnap.data()
    console.log("END")
    if (data) {
      return data
    } else {
      return {}
    }
  }

  const login = (email, password) => {
    // setError(null)
    signInWithEmailAndPassword(auth, email, password)
      .then(async (response) => {
        const res = await auth.currentUser.getIdTokenResult()
        const isAdmin = res.claims.admin
        let userData = {}
        if (res.user) {
          userData = await getUserData(res.user.uid)
        }

        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "login",
          method: "email",
        })

        dispatch({
          type: "LOGIN",
          payload: {
            user: response.user,
            isAdmin: isAdmin,
            userData: userData,
          },
        })
        toast.success("Login successful!")
      })
      .catch((error) => {
        if (
          error.code === "auth/user-not-found" ||
          error.code === "auth/wrong-password"
        ) {
          toast.error("Email and password do not match. Please try again.")
        } else {
          toast.error("An unknown error occurred. Please try again.")
          console.log(error.message)
        }
      })
  }

  return { login }
}
