// import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import { useLogout } from "../hooks/useLogout"
import { useAuthContext } from "../hooks/useAuthContext"
import { Disclosure, Menu, Transition } from "@headlessui/react"

import { Fragment, useEffect } from "react"
import Gravatar from "./Gravatar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons"
import { getPageTitle } from "../utils/analytics"

export default function Header() {
  const { logout } = useLogout()
  const { user, isAdmin } = useAuthContext()

  const location = useLocation()
  const navigate = useNavigate()

  function pathMatchRoute(route) {
    if (route === location.pathname) {
      return true
    } else if (route.length > 1 && location.pathname.startsWith(route)) {
      return true
    }
  }

  // const user = {
  //   name: "Tom Cook",
  //   email: "tom@example.com",
  //   imageUrl:
  //     "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  // }
  // const isCurrent = (path) => {
  //   return false
  // }

  const navigation = [
    {
      name: "Dashboard",
      path: "/home",
      href: "#",
      current: pathMatchRoute("/"),
      loginRequired: true,
      adminRequired: false,
    },
    {
      name: "Admin",
      path: "/admin",
      href: "#",
      current: pathMatchRoute("/admin"),
      loginRequired: true,
      adminRequired: true,
    },
    // { name: "Projects", href: "#", current: false },
    // { name: "Calendar", href: "#", current: false },
  ]
  const userNavigation = [
    {
      name: "Your Profile",
      href: "#",
      action: () => navigate("/profile"),
    },
    { name: "Settings", href: "#" },
    { name: "Sign out", action: logout, href: "#" },
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
  }

  const showNav = (item) => {
    return !item.adminRequired || (item.adminRequired && isAdmin)
  }

  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      // <HiX className="block h-6 w-6" aria-hidden="true" />
                      <FontAwesomeIcon
                        icon={faXmark}
                        className="block h-6 w-6"
                        aria-hidden="true"
                      />
                    ) : (
                      // <HiMenu className="block h-6 w-6" aria-hidden="true" />
                      <FontAwesomeIcon
                        icon={faBars}
                        className="block h-6 w-6"
                        aria-hidden="true"
                      />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-shrink-0 items-center">
                  <svg
                    width="50px"
                    height="50px"
                    viewBox="0 0 24 24"
                    fill="#2563eb"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M14.78 20H9.78C7.98 20 4.58 19.09 4.58 15.64C4.58 12.19 7.98 11.28 9.78 11.28H14.22C14.37 11.28 17.92 11.23 17.92 8.42C17.92 5.61 14.37 5.56 14.22 5.56H9.22C9.02109 5.56 8.83032 5.48098 8.68967 5.34033C8.54902 5.19968 8.47 5.00891 8.47 4.81C8.47 4.61109 8.54902 4.42032 8.68967 4.27967C8.83032 4.13902 9.02109 4.06 9.22 4.06H14.22C16.02 4.06 19.42 4.97 19.42 8.42C19.42 11.87 16.02 12.78 14.22 12.78H9.78C9.63 12.78 6.08 12.83 6.08 15.64C6.08 18.45 9.63 18.5 9.78 18.5H14.78C14.9789 18.5 15.1697 18.579 15.3103 18.7197C15.451 18.8603 15.53 19.0511 15.53 19.25C15.53 19.4489 15.451 19.6397 15.3103 19.7803C15.1697 19.921 14.9789 20 14.78 20Z"
                        fill="#2563eb"
                      ></path>{" "}
                      <path
                        d="M6.44 8.31C5.74314 8.30407 5.06363 8.09202 4.48708 7.70056C3.91054 7.30909 3.46276 6.75573 3.20018 6.11021C2.93759 5.46469 2.87195 4.75589 3.01153 4.07312C3.1511 3.39036 3.48965 2.76418 3.9845 2.2735C4.47935 1.78281 5.10837 1.44958 5.79229 1.31579C6.47622 1.182 7.18444 1.25363 7.82771 1.52167C8.47099 1.78971 9.02054 2.24215 9.40711 2.82199C9.79368 3.40182 9.99998 4.08311 10 4.78C10 5.2461 9.90773 5.70759 9.72846 6.13783C9.54919 6.56808 9.28648 6.95856 8.95551 7.28675C8.62453 7.61494 8.23184 7.87433 7.80009 8.04995C7.36834 8.22558 6.90609 8.31396 6.44 8.31ZM6.44 2.75C6.04444 2.75 5.65776 2.86729 5.32886 3.08706C4.99996 3.30682 4.74362 3.61918 4.59224 3.98463C4.44087 4.35008 4.40126 4.75221 4.47843 5.14018C4.5556 5.52814 4.74609 5.8845 5.02579 6.16421C5.3055 6.44391 5.66186 6.6344 6.04982 6.71157C6.43779 6.78874 6.83992 6.74913 7.20537 6.59776C7.57082 6.44638 7.88318 6.19003 8.10294 5.86114C8.32271 5.53224 8.44 5.14556 8.44 4.75C8.44 4.48735 8.38827 4.22728 8.28776 3.98463C8.18725 3.74198 8.03993 3.5215 7.85422 3.33578C7.6685 3.15007 7.44802 3.00275 7.20537 2.90224C6.96272 2.80173 6.70265 2.75 6.44 2.75Z"
                        fill="#3b82f6"
                      ></path>{" "}
                      <path
                        d="M17.56 22.75C16.8614 22.752 16.1779 22.5466 15.5961 22.1599C15.0143 21.7733 14.5603 21.2227 14.2916 20.5778C14.0229 19.933 13.9515 19.2229 14.0866 18.5375C14.2217 17.8521 14.5571 17.2221 15.0504 16.7275C15.5437 16.2328 16.1726 15.8956 16.8577 15.7586C17.5427 15.6215 18.253 15.6909 18.8986 15.9577C19.5442 16.2246 20.0961 16.6771 20.4844 17.2578C20.8727 17.8385 21.08 18.5214 21.08 19.22C21.08 20.1545 20.7095 21.0508 20.0496 21.7125C19.3898 22.3743 18.4945 22.7473 17.56 22.75ZM17.56 17.19C17.1644 17.19 16.7778 17.3073 16.4489 17.5271C16.12 17.7468 15.8636 18.0592 15.7122 18.4246C15.5609 18.7901 15.5213 19.1922 15.5984 19.5802C15.6756 19.9681 15.8661 20.3245 16.1458 20.6042C16.4255 20.8839 16.7819 21.0744 17.1698 21.1516C17.5578 21.2287 17.9599 21.1891 18.3254 21.0377C18.6908 20.8864 19.0032 20.63 19.2229 20.3011C19.4427 19.9722 19.56 19.5856 19.56 19.19C19.56 18.6596 19.3493 18.1508 18.9742 17.7758C18.5991 17.4007 18.0904 17.19 17.56 17.19Z"
                        fill="#1e40af"
                      ></path>{" "}
                    </g>
                  </svg>
                  {/* <img
                    className="block h-8 w-auto lg:hidden"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                    alt="Your Company"
                  />
                  <img
                    className="hidden h-8 w-auto lg:block"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                    alt="Your Company"
                  /> */}
                </div>
                {user && (
                  <div className="hidden md:ml-6 md:flex md:items-center md:space-x-4">
                    {navigation.map(
                      (item) =>
                        showNav(item) && (
                          <span
                            key={item.name}
                            // href={item.href}
                            onClick={(e) => {
                              navigate(item.path)
                            }}
                            className={classNames(
                              item.current
                                ? "bg-gray-900 text-white"
                                : "text-gray-300 hover:bg-gray-700 hover:text-white",
                              "rounded-md px-3 py-2 text-sm font-medium cursor-pointer"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            {item.name}
                          </span>
                        )
                    )}
                  </div>
                )}
              </div>
              <div className="flex items-center">
                {/* <div className="flex-shrink-0">
                  <button
                    onClick={() => navigate("/admin/games/new")}
                    type="button"
                    className="relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  >
                    <HiPlus className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    New Game
                  </button>
                </div> */}
                <div className="hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center">
                  {/* <button
                    type="button"
                    className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span className="sr-only">View notifications</span>
                    <HiBell className="h-6 w-6" aria-hidden="true" />
                  </button> */}

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="sr-only">Open user menu</span>
                        {user && (
                          <Gravatar
                            user={user}
                            className="h-8 w-8 rounded-full"
                          />
                        )}
                        {/* <img
                          className="h-8 w-8 rounded-full"
                          src={user.imageUrl}
                          alt=""
                        /> */}
                      </Menu.Button>
                    </div>
                    {!user && (
                      <div>
                        <span
                          href="#"
                          onClick={(e) => {
                            navigate("sign-in")
                          }}
                          className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium cursor-pointer"
                        >
                          Login
                        </span>
                      </div>
                    )}
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map(
                          (item) =>
                            showNav(item) && (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <span
                                    onClick={(e) => {
                                      item.action()
                                    }}
                                    // href={item.href}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                    )}
                                  >
                                    {item.name}
                                  </span>
                                )}
                              </Menu.Item>
                            )
                        )}
                        {/* <Menu.Item>
                          {({ active }) => (
                            <span
                              onClick={(e) => {
                                logout()
                              }}
                              // href={item.href}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                              )}
                            >
                              Sign Out
                            </span>
                          )}
                        </Menu.Item> */}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
              {navigation.map(
                (item) =>
                  showNav(item) && (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      onClick={(e) => {
                        navigate(item.path)
                      }}
                      className={classNames(
                        item.current
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "block rounded-md px-3 py-2 text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  )
              )}
            </div>
            <div className="border-t border-gray-700 pb-3 pt-4">
              <div className="flex items-center px-5 sm:px-6">
                <div className="flex-shrink-0">
                  <Gravatar user={user} className="h-10 w-10 rounded-full" />
                  {/* <img
                    className="h-10 w-10 rounded-full"
                    src={user.imageUrl}
                    alt=""
                  /> */}
                </div>
                {user && (
                  <div className="ml-3">
                    <div className="text-base font-medium text-white">
                      {user.name}
                    </div>
                    <div className="text-sm font-medium text-gray-400">
                      {user.email}
                    </div>
                  </div>
                )}
                {/* <button
                  type="button"
                  className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span className="sr-only">View notifications</span>
                  <HiBell className="h-6 w-6" aria-hidden="true" />
                </button> */}
              </div>
              <div className="mt-3 space-y-1 px-2 sm:px-3">
                {userNavigation.map(
                  (item) =>
                    showNav(item) && (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        // href={item.href}
                        onClick={item.action}
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                      >
                        {item.name} ++
                      </Disclosure.Button>
                    )
                )}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
    // <div className="bg-white border-b shadow-sm sticky top-0 z-40">
    //   <header className="flex justify-between items-center px-3 max-w-6xl mx-auto">
    //     <div>
    //       <div className="" onClick={() => navigate("/")}>
    //         Scavenger
    //       </div>
    //     </div>

    //     <div>
    //       <ul className="flex space-x-10">
    //         <li
    //           className={`cursor-pointer py-3 text-sm font-semibold text-gray-400 ${
    //             pathMatchRoute("/") &&
    //             "text-black border-b-blue-700 border-b-[3px]"
    //           }`}
    //           onClick={() => navigate("/")}
    //         >
    //           Home
    //         </li>
    //         {user && isAdmin && (
    //           <li
    //             className={`cursor-pointer py-3 text-sm font-semibold text-gray-400 ${
    //               pathMatchRoute("/admin") &&
    //               "text-black border-b-blue-700 border-b-[3px]"
    //             }`}
    //             onClick={() => navigate("/admin")}
    //           >
    //             Admin Portal
    //           </li>
    //         )}
    //         {user && (
    //           <li
    //             className={`cursor-pointer py-3 text-sm font-semibold text-gray-400 ${
    //               (pathMatchRoute("/sign-in") || pathMatchRoute("/profile")) &&
    //               "text-black border-b-blue-700 border-b-[3px]"
    //             }`}
    //             onClick={() => navigate("/profile")}
    //           >
    //             Profile
    //           </li>
    //         )}
    //         {!user && (
    //           <li
    //             className={`cursor-pointer py-3 text-sm font-semibold text-gray-400 ${
    //               (pathMatchRoute("/sign-in") || pathMatchRoute("/profile")) &&
    //               "text-black border-b-blue-700 border-b-[3px]"
    //             }`}
    //             onClick={() => navigate("/sign-in")}
    //           >
    //             Sign In
    //           </li>
    //         )}
    //         {user && (
    //           <li
    //             className="cursor-pointer py-3 text-sm font-semibold text-gray-400"
    //             onClick={logout}
    //           >
    //             Logout
    //           </li>
    //         )}
    //       </ul>
    //     </div>
    //   </header>
    // </div>
  )
}
