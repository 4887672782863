import { useState } from "react"
import { auth } from "../firebase/config"
import { createUserWithEmailAndPassword } from "firebase/auth"
import { useAuthContext } from "./useAuthContext"
import { toast } from "react-toastify"

export const useSignup = () => {
  const [error, setError] = useState(null)
  const { dispatch } = useAuthContext()

  const signup = (email, password) => {
    setError(null)
    createUserWithEmailAndPassword(auth, email, password)
      .then((response) => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "signUp",
          method: "email",
        })
        dispatch({ type: "LOGIN", payload: response.user })
      })
      .catch((error) => {
        console.log("Error Signing Up", error)
        setError(error.message)
        toast.error(error.message)
      })
  }

  return { error, signup }
}
