export default function Button({
  styleType = "primary",
  onClick = null,
  className = "",
  children,
}) {
  const getButtonStyle = () => {
    let classes = "py-1 px-4 mx-1 rounded transition duration-200 ease-in-out"

    switch (styleType) {
      case "primary":
        classes += " bg-sky-500 hover:bg-sky-700 text-white"
        break
      case "secondary":
        classes += " bg-gray-600 hover:bg-gray-700 text-white"
        break
      case "success":
        classes += " bg-green-600 hover:bg-green-700 text-white"
        break
      case "danger":
        classes += " bg-red-600 hover:bg-red-700 text-white"
        break
      case "warning":
        classes += " bg-yellow-600 hover:bg-yellow-700 text-white"
        break
      case "info":
        classes += " bg-cyan-400 hover:bg-cyan-500 text-white"
        break
      case "light":
        classes += " bg-gray-100 hover:bg-gray-200 text-black"
        break
      case "dark":
        classes += " bg-neutral-800 hover:bg-neutral-600 text-white"
        break
      default:
        classes += " bg-blue-600 hover:bg-blue-700 text-white"
        break
    }

    return classes
  }
  let buttonStyle = getButtonStyle()
  return (
    <button className={`${buttonStyle} ${className}`} onClick={onClick}>
      {children}
    </button>
  )
}
