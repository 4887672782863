import { Switch } from "@headlessui/react"
import { useAuthContext } from "../hooks/useAuthContext"
import { useDocument } from "../hooks/useDocument"
import { useEffect, useState } from "react"
import Navigation from "../components/Navigation"
import Input from "../components/forms/Input"
import Button from "../components/forms/Button"
import { getAuth, updateProfile } from "firebase/auth"
import { toast } from "react-toastify"
import { useFirestore } from "../hooks/useFirebase"
import { db } from "../firebase/config"
import { doc, setDoc } from "firebase/firestore"

export default function Profile() {
  const auth = getAuth()
  const { user } = useAuthContext()

  const [saving, setSaving] = useState(false)
  const [displayName, setDisplayName] = useState(user.displayName)
  const [hardModeEnabled, setHardModeEnabled] = useState(false)

  const { document: profile } = useDocument("users", user.uid)

  const pages = [{ name: user.displayName, href: "#", current: true }]

  const { updateDocument } = useFirestore("users")

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "virtualPageview",
      pageUrl: window.location.href,
      pageTitle: "Profile",
    })
  }, [])

  useEffect(() => {
    if (profile) setHardModeEnabled(profile.hardModeEnabled)
  }, [profile])

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
  }

  const saveProfile = async () => {
    setSaving(true)
    try {
      await updateProfile(auth.currentUser, {
        displayName: displayName,
      })
    } catch (error) {
      toast.error(error.message)
    }

    try {
      if (profile) {
        await updateDocument(user.uid, { hardModeEnabled: hardModeEnabled })
        toast.success("Profile saved")
      } else {
        const d = await setDoc(doc(db, "users", user.uid), {
          hardModeEnabled: hardModeEnabled,
        })
        console.log(d)
        toast.success("Profile saved")
      }
    } catch (error) {
      toast.error(error.message)
    }
    setSaving(false)
  }

  return (
    <div>
      <Navigation pages={pages} />
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-3xl">
          <div className="border-b-[1px] p-3">
            <label>Display Name - {displayName}</label>
            <Input
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
            />
          </div>
          <div className="border-b-[1px] p-3">
            <Switch.Group
              as="div"
              className="flex items-center justify-between"
            >
              <span className="flex flex-grow flex-col">
                <Switch.Label
                  as="span"
                  className="text-sm font-medium leading-6 text-gray-900"
                  passive
                >
                  Hard Mode
                </Switch.Label>
                <Switch.Description as="span" className="text-sm text-gray-500">
                  Hard mode removes various hints from puzzles.
                </Switch.Description>
              </span>
              <Switch
                checked={hardModeEnabled}
                onChange={setHardModeEnabled}
                className={classNames(
                  hardModeEnabled ? "bg-blue-600" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    hardModeEnabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>
          <div className="border-b-[1px] p-3">
            <Button
              onClick={saveProfile}
              className="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150"
            >
              Save
              {saving && (
                <svg
                  className="animate-spin -mr-1 ml-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
