import { useRef } from "react"
import { db, timestamp } from "../firebase/config"
import { addDoc, collection, doc, updateDoc } from "@firebase/firestore"

export const useFirestore = (storeCollection) => {
  const document = useRef(null)
  const error = useRef(null)

  // collection ref
  const ref = collection(db, storeCollection)

  // Add a document
  const addDocument = async (doc) => {
    error.current = null

    try {
      const created = timestamp.fromDate(new Date())
      const addedDocument = await addDoc(ref, {
        ...doc,
        created,
      })
      document.current = addedDocument.id
    } catch (error) {
      error.current = error
    }
  }

  const updateDocument = async (id, updates) => {
    try {
      await updateDoc(doc(db, storeCollection, id), updates)
    } catch (error) {
      error.current = error
    }
  }

  return { addDocument, updateDocument, document, error }
}
