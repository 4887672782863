import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useLogin } from "../hooks/useLogin"
import OAuth from "../components/OAuth"

export default function SignIn() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const { login } = useLogin()

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "virtualPageview",
      pageUrl: window.location.href,
      pageTitle: "Sign In",
    })
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    login(email, password)
  }

  return (
    <section>
      <h1 className="text-3xl text-center mt-6 font-bold">Sign In</h1>
      <div className="flex items-center justify-center">
        <div className="sm:w-full md:w-auto mx-10 mt-3 max-w-md">
          <form onSubmit={handleSubmit}>
            <label>
              <span>Email:</span>
              <input
                required
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                className="mb-6 w-full px-4 py-2 text-xl text-gray-700 bg-white border-gray-300 rounded transition ease-in-out"
              />
            </label>
            <label>
              <span>Password:</span>
              <input
                required
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                className="w-full px-4 py-2 text-xl text-gray-700 bg-white border-gray-300 rounded transition ease-in-out"
              />
            </label>
            <div className="flex justify-between whitespace-nowrap flex-wrap">
              <div className="">
                Don't have an account?
                <Link to="/sign-up" className="text-blue-600 ml-1">
                  Register
                </Link>
              </div>
              <div>
                <Link to="/forgot-password" className="text-blue-600">
                  Forgot Password?
                </Link>
              </div>
            </div>
            <button
              className="w-full bg-blue-600 text-white px-7 py-3 mt-3 text-sm font-medium uppercase rounded shadow-md hover:bg-blue-700 transition duration-150 ease-in-out hover:shadow-lg active:bg-blue-800"
              type="submit"
            >
              log in
            </button>
          </form>

          <div className="flex items-center  my-4 before:border-t before:flex-1 before:border-gray-300 after:border-t after:flex-1 after:border-gray-300">
            <p className="text-center font-semibold mx-4">OR</p>
          </div>
          <div className="mt-3">
            <OAuth></OAuth>
          </div>
        </div>
      </div>
    </section>
  )
}
