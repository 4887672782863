import moment from "moment"
import { useNavigate } from "react-router"

export default function GameCard({ game }) {
  const navigate = useNavigate()
  const goToGame = (id) => () => {
    navigate("/admin/games/" + id)
  }

  return (
    <div
      onClick={goToGame(game.id)}
      key={game.id}
      className="border-[1px] border-blue-700 text-gray-900 cursor-pointer col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow hover:bg-blue-100 hover:rounded-lg transition duration-500 ease-in-out"
    >
      <div className="flex w-full items-center justify-between space-x-6 p-6 ">
        <div className="flex-1 truncate">
          <div className="flex items-center justify-center space-x-3">
            <h3 className="truncate text-sm font-medium">{game.name}</h3>
          </div>
        </div>
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div className="flex w-0 flex-1">
            {game.startDate && (
              <span className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm">
                {/* <EnvelopeIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        /> */}
                Start Date:{" "}
                {moment(game.startDate.toDate()).format(
                  "dddd, MMMM Do YYYY, h:mm:ss a"
                )}
              </span>
            )}
          </div>
          <div className="-ml-px flex w-0 flex-1">
            <span className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm">
              {/* <PhoneIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        /> */}
              Created:{" "}
              {moment(game.created.toDate()).format(
                "dddd, MMMM Do YYYY, h:mm:ss a"
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
