import { useEffect, useState } from "react"
import Button from "../../components/forms/Button"
import Modal from "../../components/Modal"
import { useFirestore } from "../../hooks/useFirebase"
import { useNavigate } from "react-router-dom"
import Navigation from "../../components/Navigation"

export default function Portal() {
  const navigate = useNavigate()
  const { document, addDocument, error } = useFirestore("games")
  const [modalOpen, setModalOpen] = useState(false)
  const [newName, setNewName] = useState("")

  const pages = [{ name: "Admin", path: "/admin", current: true }]

  const openModal = () => {
    setModalOpen(true)
  }

  const createNewGame = async (e) => {
    await addDocument({ name: newName })
    if (!error.current) {
      navigate("/admin/games/" + document.current)
    }
  }

  const goToGameList = () => {
    navigate("/admin/games")
  }

  useEffect(() => {
    console.log("RUNNING AGAIN")
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "virtualPageview",
      pageUrl: window.location.href,
      pageTitle: "Admin Portal",
    })
  }, [])

  return (
    <div>
      <Navigation pages={pages} />
      <div className="flex items-center justify-center mt-6">
        <Button styleType="primary" onClick={openModal}>
          Create New Game
        </Button>

        <Button styleType="primary" onClick={goToGameList}>
          View Game List
        </Button>

        <Modal
          title="Create a new game"
          openModal={modalOpen}
          setModalOpen={setModalOpen}
        >
          <div className="w-full">
            <input
              className="w-full rounded transition duration-200 ease-in-out"
              type="text"
              placeholder="Game name"
              onChange={(e) => setNewName(e.target.value)}
              value={newName}
            />
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <Button onClick={createNewGame}>Create</Button>
              {/* <button
              type="button"
              className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
              onClick={() => setModalOpen(false)}
            >
              Create
            </button> */}
              <Button
                onClick={() => {
                  setModalOpen(false)
                  setNewName("")
                }}
              >
                Cancel
              </Button>
              {/* <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              onClick={() => setModalOpen(false)}
              // ref={cancelButtonRef}
            >
              Cancel
            </button> */}
            </div>
          </div>
        </Modal>
      </div>
    </div>
  )
}
