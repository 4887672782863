import { useState } from "react"
import { auth } from "../firebase/config"
import { sendPasswordResetEmail } from "firebase/auth"
import { useAuthContext } from "./useAuthContext"

export const useForgotPassword = () => {
    const [error, setError] = useState(null)
    const { dispatch } = useAuthContext()

    const forgotPassword = (email) => {
        setError(null)
        sendPasswordResetEmail(auth, email)
            .then((response) => {
                dispatch({type: "LOGOUT"})
            })
            .catch((error) => {
                console.log("Error Sending Password Reset Email", error)
                setError(error.message)
            })
    }

    return { error, forgotPassword }
}