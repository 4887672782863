import { faCheckCircle, faXmarkCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api"
import { where } from "firebase/firestore"
import moment from "moment"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import Navigation from "../../components/Navigation"
import SingleGameDetail from "../../components/SingleGameDetail"
import Button from "../../components/forms/Button"
import { timestamp } from "../../firebase/config"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useCollection } from "../../hooks/useCollection"
import { useDocument } from "../../hooks/useDocument"
import { useFirestore } from "../../hooks/useFirebase"

export default function Game() {
  let startLat = 37.233598948023406
  let startLng = -115.81241886634037
  const [finalAnswer, setFinalAnswer] = useState("")
  const [finalCorrect, setFinalCorrect] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [showFinalClue, setShowFinalClue] = useState(false)
  const [guess, setGuess] = useState(null)
  const [lat, setLat] = useState(startLat)
  const [lng, setLng] = useState(startLng)
  // const [wordArray, setWordArray] = useState(null)
  const { user, userData } = useAuthContext()

  const { id } = useParams()
  const { document: game } = useDocument("games", id)
  const { documents: userGuesses } = useCollection("userGuesses", [
    where("user", "==", user.uid),
    where("game", "==", id),
  ])

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  })
  const center = useMemo(() => ({ lat: lat, lng: lng }), [lat, lng])

  const { addDocument, updateDocument } = useFirestore("userGuesses")

  const pages = [{ name: game && game.name, href: "#", current: true }]

  const cluesAreComplete = useCallback(() => {
    if (userGuesses) {
      const userGuess = userGuesses[0]
      let detailChecks = []
      if (game.details) {
        game.details.forEach((detail) => {
          if (userGuess && !userGuess.answers) {
            return false
          }
          if (userGuess && userGuess.answer) {
            let detailAnswer = userGuess.answers.find((answer) => {
              return answer.detailId === detail.id
            })
            if (detailAnswer) {
              detailChecks.push({ id: detail.id, complete: true })
            } else {
              detailChecks.push({ id: detail.id, complete: false })
            }
          }
        })
      }
      let completed = detailChecks.filter((detail) => {
        return detail.complete === true
      })
      return (
        detailChecks.length > 0 &&
        completed.length > 0 &&
        detailChecks.length === completed.length
      )
    }
    return false
  }, [game, userGuesses])

  useEffect(() => {
    if (game && game.name) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "virtualPageview",
        pageUrl: window.location.href,
        pageTitle: "Game: " + game.name,
      })
    }
  }, [game])

  useEffect(() => {
    if (userGuesses) {
      const userGuess = userGuesses[0]
      if (!userGuess) {
        addDocument({
          game: id,
          user: user.uid,
          beginTime: timestamp.fromDate(new Date()),
        })
      } else {
        setGuess(userGuess)
        if (userGuess && userGuess.finalAnswer) {
          setFinalAnswer(userGuess.finalAnswer)
          setFinalCorrect(true)
        }
      }

      // Check if we should show the final clue
      if (cluesAreComplete()) {
        let solvedWords = []
        if (game.details) {
          game.details.forEach((detail) => {
            solvedWords.push(detail.answer)
          })

          setShowFinalClue(true)
        }
      }
    }
    setLat(game ? game.lat : startLat)
    setLng(game ? game.lng : startLng)
  }, [
    userGuesses,
    addDocument,
    id,
    user.uid,
    cluesAreComplete,
    game,
    startLat,
    startLng,
  ])

  const checkFinalAnswer = () => {
    setProcessing(true)
    const websiteConfig = process.env
    if (game) {
      fetch(websiteConfig.REACT_APP_API_URL + "/checkFinalAnswer", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.accessToken,
        },
        body: JSON.stringify({
          userId: user.uid,
          gameId: game.id,
          answer: finalAnswer,
        }),
      })
        .then((response) => response.json())
        .then(async (data) => {
          setFinalCorrect(data.correct)

          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: "submitFinalAnswer",
            submittedAnswer: finalAnswer,
            submittedAnswerCorrect: data.correct,
            submittedAnswerGameId: game.id,
          })

          if (data.correct) {
            toast.success("Final Answer Correct!")
            if (userGuesses) {
              const userGuess = userGuesses[0]
              userGuess.finalAnswer = finalAnswer
              if (!userGuess.completeTime) {
                userGuess.completeTime = timestamp.fromDate(new Date())
              }
              await updateDocument(userGuess.id, { ...userGuess })
            }
          } else {
            toast.error("Incorrect. Please try again.")
          }
        })
        .finally(() => {
          setProcessing(false)
        })
    } else {
      toast.error("Game not found. Please try again.")
    }
  }

  const updateGuess = async (guess) => {
    if (userGuesses) {
      const userGuess = userGuesses[0]
      let guessFound = false

      if (userGuess.answers) {
        userGuess.answers.forEach((answer) => {
          if (answer.detailId === guess.detailId) {
            answer.answer = guess.answer
            guessFound = true
          }
        })
      } else {
        userGuess.answers = []
      }
      if (!guessFound) {
        userGuess.answers.push(guess)
      }
      await updateDocument(userGuess.id, { ...userGuess })
    }
  }

  const getDuration = () => {
    const begin = moment(guess.beginTime.toDate())
    let complete = moment(new Date())

    if (guess.completeTime) {
      complete = moment(guess.completeTime.toDate())
    }

    const duration = moment.duration(complete.diff(begin))

    const days = Math.floor(duration.asDays())
    duration.subtract(moment.duration(days, "days"))

    const hours = duration.hours()
    duration.subtract(moment.duration(hours, "hours"))

    const minutes = duration.minutes()
    duration.subtract(moment.duration(minutes, "minutes"))

    const seconds = duration.seconds()
    return days + " d " + hours + " h " + minutes + " m " + seconds + " s"
  }

  return (
    <div>
      <Navigation pages={pages} />

      {game && (
        <div className="text-center mx-auto max-w-4xl ">
          <h1 className="text-2xl font-bold border-b-2 mb-3 py-3">
            {game.name}
          </h1>
          {guess && (
            <div className="border-b-[1px] mb-6 pb-3">
              <div className="flex justify-between border-b-[1px] mb-3 pb-3">
                <div>
                  <span className="font-bold mr-1">Started:</span>
                  {moment(guess.beginTime.toDate()).format(
                    "dddd, MMMM Do YYYY, h:mm:ss a"
                  )}
                </div>
                <div>
                  <span className="font-bold mr-1">Completed:</span>
                  {guess.completeTime
                    ? moment(guess.completeTime.toDate()).format(
                        "dddd, MMMM Do YYYY, h:mm:ss a"
                      )
                    : "Incomplete"}
                </div>
              </div>
              <div>{getDuration()}</div>
            </div>
          )}
          {game && game.details && (
            <div className="px-4 sm:px-6 lg:px-8">
              {game.details.map((detail) => (
                <div key={detail.id} className="mb-6">
                  <SingleGameDetail
                    detail={detail}
                    game={game}
                    userGuesses={userGuesses}
                    updateGuess={updateGuess}
                  />
                </div>
              ))}
              {game && (
                <div className="border-t-[1px] py-3">
                  <h1 className="text-2xl font-bold">Final Answer</h1>
                  {showFinalClue ? (
                    <div>{game.finalClue}</div>
                  ) : (
                    <div className="text-sm italic text-slate-400">
                      Complete the main clues to see the final clue
                    </div>
                  )}
                  <div className="flex w-full justify-between space-x-2 mt-3 flex-wrap">
                    <div className="flex w-full justify-center">
                      <div className="w-[35px]">
                        {finalCorrect && (
                          <span className="relative flex h-5 w-5 my-2">
                            {processing && (
                              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                            )}
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="text-green-600 text-xl h-5 w-5"
                            />
                          </span>
                        )}
                        {!finalCorrect && (
                          <span className="relative flex h-5 w-5 my-2">
                            {processing && (
                              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                            )}
                            <FontAwesomeIcon
                              icon={faXmarkCircle}
                              className="text-red-600 text-xl h-5 w-5"
                            />
                          </span>
                        )}
                      </div>
                      <div className="w-full">
                        <input
                          type="text"
                          placeholder={
                            userData && userData.hardModeEnabled
                              ? "Enter your answer"
                              : game.finalAnswer.length + " characters"
                          }
                          maxLength={
                            userData.hardModeEnabled
                              ? 1000
                              : game.finalAnswer.length
                          }
                          value={finalAnswer}
                          onChange={(e) => setFinalAnswer(e.target.value)}
                          className="w-full block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6 transition duration-250 ease-in-out"
                        />
                      </div>
                    </div>
                    <div className="flex w-full justify-end mt-3">
                      <Button
                        styleType="primary"
                        onClick={(e) => {
                          checkFinalAnswer()
                        }}
                      >
                        Check Answer
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {showFinalClue && (
        <div className="flex justify-center mb-6 mt-3">
          {!isLoaded ? (
            <h1>Loading...</h1>
          ) : (
            <div className="w-1/2 h-screen">
              <GoogleMap
                mapContainerClassName="map-container"
                center={center}
                zoom={18}
              >
                <MarkerF
                  position={{ lat: lat, lng: lng }}
                  icon={{
                    url: require("../../assests/svg/downarrow.svg").default,
                    scaledSize: new window.google.maps.Size(35, 35),
                  }}
                />
              </GoogleMap>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
