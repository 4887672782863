import { Fragment, useEffect, useState } from "react"
import { useParams } from "react-router"
import { timestamp } from "../../firebase/config"
import Button from "../../components/forms/Button"
import { useDocument } from "../../hooks/useDocument"
import moment from "moment"
import { useFirestore } from "../../hooks/useFirebase"
import { Listbox, Transition } from "@headlessui/react"
import { toast } from "react-toastify"
import SingleWordDetail from "../../components/SingleWordDetail"
import Navigation from "../../components/Navigation"
import { v4 as uuidv4 } from "uuid"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faChevronDown } from "@fortawesome/pro-thin-svg-icons"
import Input from "../../components/forms/Input"

export default function GameDetail() {
  const puzzleTypes = [
    {
      title: "Single Word",
      type: "single-word",
      description: "Single word puzzles.",
    },
    {
      title: "Multi Word",
      type: "multi-word",
      description: "Puzzles that uses letters from multiple words.",
    },
  ]
  const [edit, setEdit] = useState(false)
  const [name, setName] = useState(null)
  const [puzzleType, setPuzzleType] = useState(puzzleTypes[0])
  const [details, setDetails] = useState(null)
  const [finalClue, setFinalClue] = useState("")
  const [finalAnswer, setFinalAnswer] = useState("")
  // const [created, setCreated] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [lat, setLat] = useState("")
  const [lng, setLng] = useState("")

  const { updateDocument } = useFirestore("games")

  const { id } = useParams()
  const { document: game } = useDocument("games", id)

  const pages = [
    { name: "Admin", path: "/admin", current: false },
    { name: "Game List", path: "/admin/games", current: false },
    { name: game && game.name, href: "#", current: true },
  ]

  const setPuzzleTypeObject = (type) => {
    let typeFound = false
    for (const pt of puzzleTypes) {
      if (pt.type === type) {
        setPuzzleType(pt)
        typeFound = true
      }
    }

    if (!typeFound) {
      setPuzzleType(puzzleTypes[0])
    }
  }

  useEffect(() => {
    if (game && game.name) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "virtualPageview",
        pageUrl: window.location.href,
        pageTitle: "Admin - Game: " + game.name,
      })
    }
  }, [game])

  useEffect(() => {
    if (game) {
      setName(game.name)
      setStartDate(
        game.startDate ? game.startDate : timestamp.fromDate(new Date())
      )
      setLat(game.lat ? game.lat : null)
      setLng(game.lng ? game.lng : null)
      setFinalClue(game.finalClue ? game.finalClue : null)
      setFinalAnswer(game.finalAnswer ? game.finalAnswer : null)
      setEndDate(game.endDate ? game.endDate : timestamp.fromDate(new Date()))
      // setCreated(game.created ? game.created : timestamp.fromDate(new Date()))
      setDetails(
        game.details ? game.details : [{ id: 1, text: "", answer: "" }]
      )
      setPuzzleTypeObject(game.puzzleType)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game])

  const getPuzzleTypeName = (type) => {
    for (const pt of puzzleTypes) {
      if (pt.type === type) {
        return pt.title
      }
    }
    return puzzleTypes[0].title
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ")
  }

  const toggleEdit = () => {
    setEdit(!edit)
  }

  const saveGame = async () => {
    await updateDocument(game.id, {
      name: name,
      startDate: startDate,
      endDate: endDate,
      puzzleType: puzzleType.type,
      finalClue: finalClue,
      lat: parseFloat(lat),
      lng: parseFloat(lng),
      finalAnswer: finalAnswer,
      details: details,
    })
    toast.success("Game updated!")
  }

  const addDetails = () => {
    setDetails((current) => [...current, { id: uuidv4(), answer: "" }])
  }

  function updateDetails(updatedPuzzle) {
    const newPuzzles = details.map((puzzle) => {
      if (puzzle.id === updatedPuzzle.id) {
        return { ...updatedPuzzle }
      }
      return puzzle
    })
    setDetails(newPuzzles)
  }

  const deleteDetails = (id) => {
    setDetails((current) => current.filter((puzzle) => puzzle.id !== id))
  }

  return (
    <div>
      <Navigation pages={pages} />
      {game && (
        <div className="w-full items-center justify-center">
          <h1 className="text-3xl text-center font-bold my-6">{game.name}</h1>
          <div className="text-center">
            Created:
            <br />
            {game.created
              ? moment(game.created.toDate()).format(
                  "dddd, MMMM Do YYYY, h:mm:ss a"
                )
              : "Unknown"}
          </div>
          <div className="flex justify-center mt-6">
            <Button onClick={toggleEdit}>{edit ? "Cancel" : "Edit"}</Button>
            {edit && (
              <Button onClick={saveGame} styleType="success">
                Save
              </Button>
            )}
          </div>
        </div>
      )}

      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-3xl p-3 mt-6">
          {edit ? (
            // Edit screen
            <>
              <fieldset className="border border-solid border-blue-300 p-3 rounded-lg">
                <legend className="text-sm px-2">General Details</legend>
                <div>
                  <div className="w-full items-center justify-center px-2">
                    <div>
                      {name && (
                        <div className="w-full mb-3">
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Name
                          </label>
                          <input
                            id="name"
                            name="name"
                            type="text"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6 transition duration-250 ease-in-out"
                          />
                        </div>
                      )}
                    </div>
                    <div className="flex justify-between space-x-3">
                      {startDate && (
                        <div className="w-full mb-6">
                          <label
                            htmlFor="startDate"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Start Date
                          </label>
                          <input
                            id="startDate"
                            name="startDate"
                            type="datetime-local"
                            onChange={(e) =>
                              setStartDate(
                                timestamp.fromDate(new Date(e.target.value))
                              )
                            }
                            value={moment(startDate.toDate()).format(
                              "YYYY-MM-DDTHH:mm"
                            )}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6 transition duration-250 ease-in-out"
                            max={moment(endDate.toDate()).format(
                              "YYYY-MM-DDTHH:mm"
                            )}
                          />
                        </div>
                      )}
                      {endDate && (
                        <div className="w-full">
                          <label
                            htmlFor="endDate"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            End Date
                          </label>
                          <input
                            id="endDate"
                            name="endDate"
                            type="datetime-local"
                            onChange={(e) =>
                              setEndDate(
                                timestamp.fromDate(new Date(e.target.value))
                              )
                            }
                            value={moment(endDate.toDate()).format(
                              "YYYY-MM-DDTHH:mm"
                            )}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6 transition duration-250 ease-in-out"
                            min={moment(startDate.toDate()).format(
                              "YYYY-MM-DDTHH:mm"
                            )}
                          />
                        </div>
                      )}
                    </div>
                    <div>
                      <label
                        htmlFor="puzzle-type"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Puzzle Type
                      </label>
                      <Listbox
                        value="puzzleType"
                        onChange={setPuzzleType}
                        id="puzzle-type"
                      >
                        {({ open }) => (
                          <>
                            <Listbox.Label className="sr-only">
                              Puzzle Type
                            </Listbox.Label>
                            <div className="relative">
                              <div className="inline-flex divide-x divide-blue-700 rounded-md shadow-sm">
                                <div className="inline-flex items-center gap-x-1.5 rounded-l-md bg-blue-600 px-3 py-2 text-white shadow-sm">
                                  <p className="text-sm font-semibold">
                                    {puzzleType && puzzleType.title}
                                  </p>
                                </div>
                                <Listbox.Button className="inline-flex items-center rounded-l-none rounded-r-md bg-blue-600 p-2 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 focus:ring-offset-gray-50">
                                  <span className="sr-only">
                                    Change published status
                                  </span>
                                  {/* <BsChevronDown className="h-5 w-5 text-white" /> */}
                                  <FontAwesomeIcon
                                    icon={faChevronDown}
                                    className="h-5 w-5 text-white"
                                  />
                                </Listbox.Button>
                              </div>

                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="z-10 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  {puzzleTypes.map((option) => (
                                    <Listbox.Option
                                      key={option.title}
                                      className={({ active }) =>
                                        classNames(
                                          active
                                            ? "bg-blue-600 text-white"
                                            : "text-gray-900",
                                          "cursor-default select-none p-4 text-sm"
                                        )
                                      }
                                      value={option}
                                    >
                                      {({ selected, active }) => (
                                        <div className="flex flex-col">
                                          <div className="flex justify-between">
                                            <p
                                              className={
                                                selected
                                                  ? "font-semibold"
                                                  : "font-normal"
                                              }
                                            >
                                              {option.title}
                                            </p>
                                            {selected ? (
                                              <span
                                                className={
                                                  active
                                                    ? "text-white"
                                                    : "text-blue-600"
                                                }
                                              >
                                                {/* <BsCheck
                                                  className="h-5 w-5"
                                                  aria-hidden="true"
                                                /> */}
                                                <FontAwesomeIcon
                                                  icon={faCheck}
                                                  className="h-5 w-5"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            ) : null}
                                          </div>
                                          <p
                                            className={classNames(
                                              active
                                                ? "text-blue-200"
                                                : "text-gray-500",
                                              "mt-2"
                                            )}
                                          >
                                            {option.description}
                                          </p>
                                        </div>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    </div>
                  </div>
                </div>
              </fieldset>

              {/* Puzzle Details */}
              <fieldset className="border border-solid border-blue-300 p-3 mt-6 rounded-lg">
                <legend className="text-sm px-2">Puzzle Details</legend>
                <div className="px-2">
                  {details &&
                    puzzleType.type === "single-word" &&
                    details.map((detail, index) => (
                      <div key={index}>
                        <SingleWordDetail
                          puzzle={detail}
                          updateDetails={updateDetails}
                          deleteDetails={deleteDetails}
                        />
                      </div>
                    ))}
                  <div className="mt-3">
                    <Button styleType="info" onClick={addDetails}>
                      Add More Details
                    </Button>
                  </div>
                </div>
              </fieldset>

              {/* Puzzle Final Answer */}
              <fieldset className="border border-solid border-blue-300 p-3 mt-6 rounded-lg">
                <legend className="text-sm px-2">Puzzle Answer</legend>
                <div className="px-2">
                  <label
                    htmlFor="details"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    <div className="flex justify-between">
                      <div>Final Answer Clue</div>
                    </div>
                  </label>
                  <textarea
                    name="details"
                    id="details"
                    rows={5}
                    className="mb-6 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6 transition duration-250 ease-in-out"
                    value={finalClue}
                    onChange={(e) => setFinalClue(e.target.value)}
                  ></textarea>

                  <div className="flex justify-between mb-6 w-full space-x-5">
                    <div className="w-full">
                      <label
                        htmlFor="map-lat"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Latitude
                      </label>
                      <Input
                        type="number"
                        value={lat}
                        onChange={(e) => setLat(e.target.value)}
                      />
                      {/* <input type="text" name="map-lat" id="map-lat" /> */}
                    </div>

                    <div className="w-full">
                      <label
                        type="number"
                        htmlFor="map-lng"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Longitude
                      </label>
                      <Input
                        value={lng}
                        onChange={(e) => setLng(e.target.value)}
                      />
                      {/* <input type="text" name="map-lng" id="map-lng" /> */}
                    </div>
                  </div>

                  <label
                    htmlFor="final-answer"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Final Answer
                  </label>
                  <input
                    type="text"
                    name="final-answer"
                    id="final-answer"
                    value={finalAnswer}
                    onChange={(e) => setFinalAnswer(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 transition duration-250 ease-in-out"
                  />
                </div>
              </fieldset>
            </>
          ) : (
            // View screen
            <>
              <div className="flex justify-center space-x-10">
                <div>
                  Start Date:
                  <br />
                  {game && game.startDate
                    ? moment(game.startDate.toDate()).format(
                        "dddd, MMMM Do YYYY, h:mm:ss a"
                      )
                    : "Pending..."}
                </div>
                <div>
                  End Date:
                  <br />
                  {game && game.endDate
                    ? moment(game.endDate.toDate()).format(
                        "dddd, MMMM Do YYYY, h:mm:ss a"
                      )
                    : "Pending..."}
                </div>
              </div>
              {game && (
                <div className="flex my-6 justify-center space-x-10">
                  Puzzle Type: {getPuzzleTypeName(game.puzzleType)}
                </div>
              )}
              {game &&
                game.details &&
                game.puzzleType === "single-word" &&
                game.details.map((detail, index) => (
                  <div key={detail.id} className="py-6 border-t-[1px]">
                    <div className="text-lg font-bold">Puzzle Text:</div>
                    <div>{detail.text}</div>
                    <div className="text-lg font-bold mt-1">Puzzle Answer:</div>
                    <div>{detail.answer}</div>
                  </div>
                ))}
              {game && (
                <div className="py-6 border-t-[1px]">
                  <div className="text-lg font-bold">Final Clue:</div>
                  <div>{game.finalClue}</div>
                  <div className="flex text-lg font-bold py-6">
                    <div className="mr-2">Final Answer:</div>
                    <div className="text-green-600">{game.finalAnswer}</div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
