import { useCollection } from "../hooks/useCollection"
import { useNavigate } from "react-router"
import Navigation from "../components/Navigation"
import { useEffect } from "react"

export default function Home() {
  const navigate = useNavigate()
  const { documents: games } = useCollection("games")

  const pages = [{ name: "Home", path: "/", current: true }]

  const goToGame = (id) => () => {
    navigate("/games/" + id)
  }

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "virtualPageview",
      pageUrl: window.location.href,
      pageTitle: "Home",
    })
  }, [])

  return (
    <section>
      <Navigation pages={pages} />
      <div className="max-w-6xl mx-auto px-3">
        <div className="text-3xl text-center mt-6 font-bold">
          Welcome to the Game!
        </div>
        <div className="text-xl text-center mt-6">Pick a Game</div>
      </div>
      <div className="text-center mt-6">
        <div className="w-full">
          <ul>
            {games &&
              games.map((game) => (
                <li
                  key={game.id}
                  className="cursor-pointer text-blue-500 hover:underline"
                  onClick={goToGame(game.id)}
                >
                  <div key={game.id}>{game.name}</div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </section>
  )
}
