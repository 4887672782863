import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom"

import Header from "./components/Header"
import Home from "./pages/Home"
import Profile from "./pages/Profile"
import SignIn from "./pages/SignIn"
import SignUp from "./pages/SignUp"
import ForgotPassword from "./pages/ForgotPassword"
import { useAuthContext } from "./hooks/useAuthContext"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Portal from "./pages/admin/Portal"
import GameDetail from "./pages/admin/GameDetail"
import GameList from "./pages/admin/GameList"
import Footer from "./components/Footer"
import Game from "./pages/users/Game"
import "./App.scss"
import Privacy from "./pages/Privacy"
import TermsOfService from "./pages/TermsOfService"
import Index from "./pages/Index"

function App() {
  const { user, authIsReady, isAdmin } = useAuthContext()

  return (
    <>
      {authIsReady && (
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<Index />} />
            <Route
              path="/home"
              element={user ? <Home /> : <Navigate to="/sign-in" />}
            />
            <Route
              path="/admin"
              element={user && isAdmin ? <Portal /> : <Navigate to="/sign-in" />}
            />
            <Route
              path="/admin/games"
              element={user && isAdmin ? <GameList /> : <Navigate to="/sign-in" />}
            />
            <Route
              path="/admin/games/:id"
              element={user && isAdmin ? <GameDetail /> : <Navigate to="/sign-in" />}
            />
            <Route path="/games/:id" element={<Game />} />
            <Route
              path="/profile"
              element={user ? <Profile /> : <Navigate to="/sign-in" />}
            />
            <Route
              path="/sign-in"
              element={user ? <Navigate to="/" /> : <SignIn />}
            />
            <Route
              path="/sign-up"
              element={user ? <Navigate to="/" /> : <SignUp />}
            />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      )}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  )
}

export default App
