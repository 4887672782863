import { auth } from "../firebase/config"
import { getAdditionalUserInfo, signInWithPopup } from "firebase/auth"
import { useAuthContext } from "./useAuthContext"
import { toast } from "react-toastify"

export const useOAuthLogin = () => {
  const { dispatch } = useAuthContext()

  const oAuthLogin = (provider) => {
    signInWithPopup(auth, provider)
      .then(async (response) => {
        const { isNewUser } = getAdditionalUserInfo(response)

        const res = await auth.currentUser.getIdTokenResult()
        const isAdmin = res.claims.admin
        console.log(provider.providerId)

        if (isNewUser) {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: "signUp",
            method: provider.providerId,
          })
        } else {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: "login",
            method: provider.providerId,
          })
        }

        dispatch({
          type: "LOGIN",
          payload: { user: response.user, isAdmin: isAdmin },
        })
      })
      .catch((error) => {
        console.log("Error Logging In", error)
        toast.error(error.message)
      })
  }

  return { oAuthLogin }
}
