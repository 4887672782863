export default function SingleWordDetail({
  puzzle,
  updateDetails,
  deleteDetails,
}) {
  const setPuzzleText = (e) => {
    puzzle.text = e.target.value
    updateDetails(puzzle)
  }

  const setPuzzleAnswer = (e) => {
    puzzle.answer = e.target.value
    updateDetails(puzzle)
  }
  return (
    <div className="pb-10 mb-10 border-b-2 border-blue-300 border-dotted">
      <label
        htmlFor="details"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        <div className="flex justify-between">
          <div>Puzzle Text</div>
          <div
            className="cursor-pointer mr-1"
            onClick={() => deleteDetails(puzzle.id)}
          >
            Delete
          </div>
        </div>
      </label>
      <textarea
        name="details"
        id="details"
        rows={5}
        className="mb-6 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6 transition duration-250 ease-in-out"
        value={puzzle.text}
        onChange={setPuzzleText}
      ></textarea>

      <label
        htmlFor="answer"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        Answer
      </label>
      <input
        type="text"
        name="answer"
        id="answer"
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6 transition duration-250 ease-in-out"
        value={puzzle.answer}
        onChange={setPuzzleAnswer}
      />
    </div>
  )
}
