import { useEffect, useState } from "react"
import { useAuthContext } from "../hooks/useAuthContext"
import Button from "./forms/Button"
import { toast } from "react-toastify"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faXmarkCircle } from "@fortawesome/free-solid-svg-icons"

export default function SingleHuntDetail({
  detail,
  hunt,
  userGuesses,
  updateGuess,
}) {
  const [answer, setAnswer] = useState("")
  const [correct, setCorrect] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [guess, setGuess] = useState(answer)

  const { user, userData } = useAuthContext()

  useEffect(() => {
    if (userGuesses) {
      const userGuess = userGuesses[0]

      if (userGuess && userGuess.answers) {
        const answers = userGuess.answers.find(
          (guess) => guess.detailId === detail.id
        )
        if (answers) {
          setGuess(answers)
          setAnswer(answers.answer)
          setCorrect(true)
        }
      }
    }
  }, [detail, userGuesses, guess])

  const checkAnswer = (huntId, detailId) => {
    setProcessing(true)
    const websiteConfig = process.env

    fetch(websiteConfig.REACT_APP_API_URL + "/checkAnswer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.accessToken,
      },
      // mode: "cors",
      body: JSON.stringify({
        answer: answer,
        huntId: huntId,
        detailId: detailId,
        userId: user.uid,
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        setCorrect(data.correct)

        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "submitAnswer",
          submittedAnswer: answer,
          submittedAnswerCorrect: data.correct,
          submittedAnswerGameId: huntId,
          submittedAnswerDetailId: detailId,
        })

        if (data.correct) {
          //   await setGuess({ detailId: detail.id, answer: answer })
          updateGuess({ detailId: detail.id, answer: answer })
          //   if (guess) {
          // guess.answers.forEach((answer) => {
          //   if (answer.detailId === detail.id) {
          //     answer.answer = answer
          //   }
          // })

          // await updateDocument(guess.id, {
          //   ...guess,
          // })
          //     console.log("GUESS EXISTS")
          //   } else {
          //     console.log("GUESS IS NULL")
          //     console.log("DOUBLE CHECK: ", guess)

          //     await updateDocument(guess.id, {
          //       ...guess,
          //     })
          //   }
          toast.success("Correct!")
        } else {
          toast.error("Incorrect. Please try again.")
        }
      })
      .finally(() => {
        setProcessing(false)
      })
  }

  return (
    <>
      <div>{detail.text}</div>
      <div className="flex w-full justify-between space-x-2 mt-3 flex-wrap">
        <div className="flex w-full justify-center">
          <div className="w-[35px]">
            {correct && (
              <span className="relative flex h-5 w-5 my-2">
                {processing && (
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                )}
                {/* <BsFillCheckCircleFill className="text-green-600 text-xl h-5 w-5" /> */}
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-green-600 text-xl h-5 w-5"
                />
              </span>
            )}
            {!correct && (
              <span className="relative flex h-5 w-5 my-2">
                {processing && (
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                )}
                {/* <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"> */}
                {/* <BsFillXCircleFill className="text-red-600 text-xl h-5 w-5" /> */}
                <FontAwesomeIcon
                  icon={faXmarkCircle}
                  className="text-red-600 text-xl h-5 w-5"
                />
                {/* </span> */}
              </span>
            )}
          </div>
          <div className="w-full">
            <input
              type="text"
              placeholder={
                userData.hardModeEnabled
                  ? "Enter your answer"
                  : detail.answer.length + " characters"
              }
              maxLength={userData.hardModeEnabled ? 1000 : detail.answer.length}
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              // onChange={(e) => updateWords(detail.id, e.target.value)}
              className="w-full block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6 transition duration-250 ease-in-out"
            />
          </div>
        </div>
        <div className="flex w-full justify-end mt-3">
          <Button
            styleType="primary"
            onClick={(e) => {
              checkAnswer(hunt.id, detail.id)
            }}
            className="check-sub-answer"
          >
            Check Answer
          </Button>
        </div>
      </div>
    </>
  )
}
