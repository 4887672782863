import { useEffect, useState, useRef } from "react"
import { db } from "../firebase/config"
import { collectionGroup, onSnapshot, query } from "firebase/firestore"

export const useCollection = (c, _wheres, _orderBy) => {
  const [documents, setDocuments] = useState(null)
  const [error, setError] = useState(null)

  // if we don't use a ref --> infinite loop in useEffect
  // _query is an array and is "different" on every function call
  const wheres = useRef(_wheres).current
  const orderBy = useRef(_orderBy).current

  useEffect(() => {
    let ref = collectionGroup(db, c)

    if (wheres) {
      ref = query(ref, ...wheres)
    }
    // if (orderBy) {
    //   ref = ref.orderBy(...orderBy)
    // }
    const unsubscribe = onSnapshot(
      ref,
      (snapshot) => {
        let results = []
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id })
        })

        // update state
        setDocuments(results)
        setError(null)
      },
      (error) => {
        console.log(error)
        setError("could not fetch the data")
      }
    )

    // unsubscribe on unmount
    return () => unsubscribe()
  }, [orderBy, c, wheres])

  return { documents, error }
}
// import { useState, useEffect } from "react"
// import { db } from "../firebase/config"

// // firebase imports
// import { collection, onSnapshot } from "@firebase/firestore"

// export const useCollection = (c) => {
//   const [documents, setDocuments] = useState(null)

//   useEffect(() => {
//     let ref = collection(db, c)

//     const unsub = onSnapshot(ref, (snapshot) => {
//       let results = []
//       snapshot.docs.forEach((doc) => {
//         results.push({ ...doc.data(), id: doc.id })
//       })
//       console.log("Results: ", results)
//       setDocuments(results)
//     })

//     return () => unsub()
//   }, [c])

//   return { documents }
// }
