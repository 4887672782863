import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useForgotPassword } from "../hooks/useForgotPassword"

export default function ForgotPassword() {
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const { error, forgotPassword } = useForgotPassword()

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "virtualPageview",
      pageUrl: window.location.href,
      pageTitle: "Forgot Password",
    })
  }, [])

  const handleSubmit = (e) => {
    setMessage("")
    e.preventDefault()
    forgotPassword(email)
    setMessage("Check your inbox for further instructions")
  }

  return (
    <section>
      <h1 className="text-3xl text-center my-6 font-bold">Forgot Password</h1>
      <div className="items-center justify-center flex">
        <div className="sm:w-full md:w-auto">
          <form onSubmit={handleSubmit}>
            <label>
              <span>Email:</span>
              <input
                required
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                className="mb-6 w-full px-4 py-2 text-xl text-gray-700 bg-white border-gray-300 rounded transition ease-in-out"
              />
            </label>
            <div className="flex justify-between whitespace-nowrap">
              <p className="mb-6">
                Already have an account?
                <Link to="/sign-in" className="text-blue-600 ml-1">
                  Sign In
                </Link>
              </p>
            </div>
            <button
              className="w-full bg-blue-600 text-white px-7 py-3 mt-3 text-sm font-medium uppercase rounded shadow-md hover:bg-blue-700 transition duration-150 ease-in-out hover:shadow-lg active:bg-blue-800"
              type="submit"
            >
              Reset Password
            </button>

            {error && <p>{error}</p>}

            {message && <p>{message}</p>}
          </form>
        </div>
      </div>
    </section>
  )
}
