export default function Input({ value, onChange, type = "text" }) {
  return (
    <>
      <input
        type={type}
        value={value}
        onChange={onChange}
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6 transition duration-250 ease-in-out"
      />
    </>
  )
}
